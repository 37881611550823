<template>
    <div>
        <el-form :model="messageInfo" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="广告正文：" prop="page" class="pageSelect" require>
                <emoji-input palce="正文：介绍广告内容" type="textarea" v-model="messageInfo.message"></emoji-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import {pageInstagrams,pagePosts} from '@/api/creatAd'
    import emojiInput from '../../../createAd/components/emojiInput'
    export default {
        props:['messageMsg'],
        data () {
            return {
                messageInfo:JSON.parse(JSON.stringify(this.messageMsg))
            }
        },
        components:{
            emojiInput
        },
        methods: {
            setMsg(){
                this.$emit('getMessageMsg',this.messageInfo)
            },
        },
        watch: {
            messageMsg:{
                handler(){
                    this.messageInfo = {...this.messageMsg}
                },
                deep:true
            },
            messageInfo:{
                handler(){
                    this.setMsg()
                },
                deep:true
            }
        }
    }
</script>
<style lang="scss">
    .emojiInputWrap .el-textarea__inner{
        height: 120px;
    }
</style>